import { Tooltip, Typography } from "@mui/material";
// import makeStyles from "@mui/styles/makeStyles";

// const useStyles = makeStyles((theme) => ({
//   arrow: {
//     backgroundColor: "#46bacf",
//     boxShadow: theme.shadows[8],
//     padding: 15,
//   },
//   tooltip: {
//     // backgroundColor: "#34cbe7",
//     backgroundColor: "white",
//     padding: "4px 8px",
//     zIndex: "1002",
//     color: "#028ab4",
//   },
//   root: {},
// }));

const CustomTooltip = (props) => {
  // const classes = useStyles();

  return (
    <Tooltip
    //   className={classes.root}
    componentsProps={{
        tooltip: {
          sx: {
            bgcolor: '#805d35',
            '& .MuiTooltip-arrow': {
              color: '#805d35',
            },
          },
        },
      }}
      arrow
    //   classes={{
    //     tooltip: classes.tooltip,
    //     tooltipArrow: classes.arrow,
    //     arrow: classes.smallArrow,
    //   }}
      placement={props.position || "bottom"}
      title={
        <Typography
          textAlign="center"
          variant="subtitle1"
          sx={{
            fontFamily: "Helvetica",
            fontSize: "14px",
            fontWeight: "normal",
          }}
        >
          {props.title}
        </Typography>
      }
    >
      {props.children}
    </Tooltip>
  );
};

export default CustomTooltip;
