import { useLocation, useNavigate } from "react-router-dom";
import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useEffect, useState } from "react";
import SelectLanguage from "./selectLanguage";
import axios from "axios";
// import CustomTooltip from "../Tooltip";
import upRightLogo from "./assets/nayiSansadKiKala.png";
import { useDispatch, useSelector } from "react-redux";
import { setGate } from "../../store/gateReducer";
import Drawer from "./drawer";
import { MenuRounded } from "@mui/icons-material";
import FullScreenDialog from "../Modal/fullScreenModal";
import { GATES_URL, NAVIGATION_URL } from "../../utils/applicationUrl";

const useStyles = makeStyles((theme) => ({
  appbar: {
    backgroundColor: "white",
    maxHeight: "100px",
    padding: "8px 10px",
    zIndex: "1001",
    position: "fixed",
    boxShadow: "0px 10px 30px #00000014",
    [theme.breakpoints.down("md")]: {
      height: "80px !important",
    },
    [theme.breakpoints.down("sm")]: {
      height: "70px !important",
      padding: "8px 0",
    },
    [theme.breakpoints.down("xsm")]: {
      height: "50px !important",
    },
    // border: "10px solid red"
  },
  toolbar: {
    minHeight: "unset",
    height: "90px !important",
    [theme.breakpoints.down("md")]: {
      height: "80px !important",
    },
    [theme.breakpoints.down("sm")]: {
      height: "70px !important",
    },
    [theme.breakpoints.down("xsm")]: {
      height: "50px !important",
    },
  },
  logosWrapperBox: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      height: "80px !important",
    },
    [theme.breakpoints.down("sm")]: {
      height: "70px !important",
    },
    [theme.breakpoints.down("xsm")]: {
      height: "50px !important",
    },
  },
  upperCitiisLogo: {
    height: 80,
    [theme.breakpoints.down("sm")]: {
      height: "40px",
    },
  },
  upperGovtLogo: {
    height: 70,
    cursor:'pointer',
    [theme.breakpoints.down("md")]: {
      height: "60px !important",
      paddingBottom: "10px",
    },
    [theme.breakpoints.between(1000, 1310)]: {
      height: "50px !important",
    },
    [theme.breakpoints.down("sm")]: {
      height: "60px !important",
    },
    [theme.breakpoints.down("xsm")]: {
      height: "40px !important",
    },
  },
  upperGovtLogo2: {
    height: 60,
    [theme.breakpoints.down("md")]: {
      height: "60px !important",
      paddingBottom: "10px",
    },
    [theme.breakpoints.between(1000, 1310)]: {
      height: "50px !important",
    },
    [theme.breakpoints.down("sm")]: {
      height: "60px !important",
    },
    [theme.breakpoints.down("xsm")]: {
      height: "40px !important",
    },
  },
  menus: {
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: "bold",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      fontSize: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "8px",
    },
    [theme.breakpoints.down("xsm")]: {
      fontSize: "7px",
    },
  },
}));

const Header = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const gate = useSelector((state) => state.gate.gate_type);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data, setData] = useState();
  const [drawerData, setDrawerData] = useState();

  useEffect(() => {
    axios({
      url: `${NAVIGATION_URL}?language=${localStorage.getItem('SansadLanguage')}`,
      method: "get",
    })
      .then((res) => {
        setDrawerData(res.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    dispatch(setGate(""));
  }, [location.pathname, dispatch]);

  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      {location.pathname !== "/" && (
        <FullScreenDialog
          dialogOpen={dialogOpen}
          setDialogOpen={setDialogOpen}
          data={drawerData}
        />
      )}
      {drawerData && (
        <Drawer
          dataa={drawerData}
          gate={gate}
          open={open}
          setOpen={setOpen}
          list={Object.keys(drawerData)}
        />
      )}
      <AppBar position="sticky" elevation={10} className={classes.appbar}>
        <Toolbar className={classes.toolbar}>
          <Box className={classes.logosWrapperBox}>
            <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
              <img
                src={require("./assets/govtofindia.png")}
                alt="govtofindia"
                className={classes.upperGovtLogo}
              />
              {window.innerWidth > 800 && location.pathname !== "/" &&
                location.pathname !== "/welcome" && (
                  <IconButton
                    onClick={() => {
                      setDialogOpen(true);
                    }}
                  >
                    <MenuRounded sx={{ fontSize: "40px" }} />
                  </IconButton>
                )}
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {window.innerWidth > 800 && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    marginRight: "10px",
                  }}
                >
                  {location.pathname !== "/" && <SelectLanguage />}
                  <img
                    alt=""
                    src={require("./assets/g20.png")}
                    className={classes.upperGovtLogo2}
                  />
                </div>
              )}
              <img src={upRightLogo} onClick={()=>window.location.replace("/")}
               alt="" className={classes.upperGovtLogo} />
              {window.innerWidth < 800 && (
                <IconButton onClick={() => setOpen(true)}>
                  <MenuRounded
                    sx={{ color: "#db7a11", height: "35px", width: "35px" }}
                  />
                </IconButton>
              )}
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;
