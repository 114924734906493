import {configureStore} from '@reduxjs/toolkit'
import gateReducer from './gateReducer'
import languageReducer from './languageReducer'
import loaderReducer from './loader/loaderReducer'

export default configureStore({
    reducer: {
        language: languageReducer,
        gate: gateReducer,
        loader: loaderReducer
    }
})