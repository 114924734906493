import Header from "./components/Header";
import makeStyles from "@mui/styles/makeStyles";
// import { Box, ButtonBase, Paper } from "@mui/material";
import clsx from "clsx";
import { AppRoutes } from "./routes";
import Loader from "./components/Loader";
import { useSelector } from "react-redux";
import './i18n'
import DocumentMeta from "react-document-meta";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    position: "relative",
    padding: "100px 0 0 0", // adds top height
    [theme.breakpoints.down("md")]: {
      padding: "80px 0 0 0",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "70px 0 0 0",
    },
    [theme.breakpoints.down("xsm")]: {
      padding: "50px 0 0 0",
    },
  },
}));

function App() {
  const classes = useStyles();
  const meta={
    title: 'Sansad ki Kala',
    description: 'Sansad ki Kala',
    canonical: 'https://sansadkikala.ignca.gov.in/',
    meta: {
        charset: 'utf-8',
        name: {
            keywords: 'sansad ,new parliament, parliament, nai sansad, sansad bhavan'
        }
    }
  }
  const loader = useSelector((state) => state.loader);
  return (
    <DocumentMeta {...meta}>
    <div className={clsx(classes.root)}>
      {loader.open && <Loader />}
      <Header />
      <AppRoutes />
    </div>
      </DocumentMeta>
  );
}

export default App;
