import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import { makeStyles } from "@mui/styles";
import { Box, ButtonBase, Grid, Paper } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import CustomTooltip from "../Tooltip";
import { useNavigate } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    background: "none",
  },
  brownSurface: {
    height: "90vh",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    scrollbarWidth: "none",
    backgroundColor: "#E3CAA5",
    borderRadius: 0,
    borderBottomLeftRadius: "40px",
    borderBottomRightRadius: "40px",
    width: "94vw",
    marginLeft: "auto",
    marginRight: "auto",
    position: "relative",
    padding: "60px",
  },
  title: {
    fontFamily: "Poppins",
    fontWeight: 700,
    color: "#585858",
    fontSize: "16px",
  },
  gateTitle: {
    fontFamily: "Poppins",
    fontWeight: 500,
    color: "#585858",
    fontSize: "14px",
    cursor: "pointer",
    marginTop: "10px",
    width: "max-content",
  },
  gateSubtitle: {
    fontFamily: "Poppins",
    fontWeight: 300,
    color: "#585858",
    fontSize: "12px",
  },
  subSection: {
    fontFamily: "Poppins",
    fontWeight: 300,
    color: "#585858",
    fontSize: "12px",
    paddingLeft: "20px",
    cursor: 'pointer',
    width: 'max-content'
  },
  close: {
    position: "absolute",
    top: 20,
    right: 20,
    fontWeight: 600,
    color: "white",
    fontFamily: "Poppins",
    fontSize: "16px",
  },
  gateBox: {
    marginBottom: "30px",
  },
}));

export default function FullScreenDialog(props) {
  const { dialogOpen: open, setDialogOpen: setOpen, data } = props;
  const classes = useStyles();
  const navigate = useNavigate()

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      classes={{
        paper: classes.root,
      }}
      onClick={handleClose}
    >
      <Paper elevation={0} className={classes.brownSurface}>
        <ButtonBase className={classes.close} onClick={() => setOpen(false)}>
          CLOSE
          <CloseRounded />
        </ButtonBase>
        <Grid container>
          {data &&
            [
              [
                data?.NORTH_ENTRANCE,
                data?.NORTH_EAST_ENTRANCE,
                data?.SOUTH_ENTRANCE,
              ],
              [data?.WEST_ENTRANCE],
              [data?.SOUTH_EAST_ENTRANCE],
              [data?.EAST_ENTRANCE, data?.CONSTITUTIONAL_FOYERS],
            ].map((grid) => (
              <Grid item xs={3}>
                <Box className={classes.column}>
                  {grid?.map((ele, index) => {
                    let item = ele[0];
                    return (
                      <Box className={classes.gateBox}>
                        <Typography className={classes.title}>
                          {item.entrance_type.replaceAll("_", " ")}
                        </Typography>
                        {ele.map((gate) => (
                          <>
                            <CustomTooltip
                              position="right"
                              title={`Click to view ${gate?.detail_page_title}`}
                            >
                              <Typography onClick={() => {
                                navigate(`/details${gate?.pagelink}`)
                                setOpen(false)
                              }} className={classes.gateTitle}>
                                {gate?.detail_page_title}
                                {gate?.detail_page_sub_title && (
                                  <Typography className={classes.gateSubtitle}>
                                    {gate?.detail_page_sub_title}
                                  </Typography>
                                )}
                              </Typography>
                            </CustomTooltip>
                            {gate?.sections?.length > 1 &&
                              gate?.sections?.map((subSection, index) => (
                                <CustomTooltip position="right" title={`Click to view ${subSection}`} >
                                <Typography onClick={() => {
                                  navigate(`/details${gate?.pagelink}?goto=${index}`)
                                  setOpen(false)
                                }} className={classes.subSection}>
                                  {subSection}
                                </Typography></CustomTooltip>
                              ))}
                          </>
                        ))}
                      </Box>
                    );
                  })}
                </Box>
              </Grid>
            ))}
        </Grid>
      </Paper>
    </Dialog>
  );
}
