import { CircularProgress } from "@mui/material";

const Loader = () => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: "100vw",
        height: "100vh",
        backdropFilter: "blur(5px)",
        zIndex: 1010, 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center'
      }}
    >
      <CircularProgress size={100} />
    </div>
  );
};

export default Loader;
