import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    language: {
        gate_type: ''
    }
}

export const gateReducer = createSlice({
    name: 'gate', 
    initialState, 
    reducers: {
        setGate: (state, action) => {
            state.gate_type = action.payload
        },
    }
})

export const {setGate} = gateReducer.actions
export default gateReducer.reducer