import React from "react";
import { Routes, Route } from "react-router-dom";
import Loader from "./components/Loader";

const Home = React.lazy(() => import("./views/Home"));
const Welcome = React.lazy(() => import("./views/Intro"))
const Welcome2 = React.lazy(() => import("./views/Intro2"))
const Menu = React.lazy(() => import('./views/Menu'))
const DetailPage = React.lazy(() => import("./views/GateDetails"))

export const routes = [
  { path: "", element: Home },
  { path: '/welcome', element: Welcome},
  { path: '/welcome2', element: Welcome2},
  { path: '/menu', element: Menu},
  { path: '/details/*', element: DetailPage}
];

export const AppRoutes = () => {
  return (
    <Routes>
      {routes.map((route, index) => {
        return <Route
        key={index}
        path={route.path}
        element={
          <React.Suspense fallback={<Loader />}>
            <route.element />
          </React.Suspense>
        }
      />
      })}
    </Routes>
  );
};
