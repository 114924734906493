import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";
import StyledEngineProvider from "@mui/styled-engine/StyledEngineProvider";
import { BrowserRouter } from "react-router-dom";
import store from "./store";
import { Provider } from "react-redux";
import './index.css'
import '@fontsource/poppins/400.css'
import '@fontsource/poppins/500.css'
import '@fontsource/poppins/800.css'
import '@fontsource/poppins/700.css'
import '@fontsource/poppins/600.css'
import '@fontsource/alice'
let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      xsm: 600,
      sm: 800,
      md: 950,
      mlg: 1050,
      lg: 1200,
      xlg: 1400,
      xl: 1536,
    },
  },
  typography: {
    bodyText: {
      fontSize: "22px",
      color: "#404040",
      fontFamily: "Poppins",
    },
    headText: {
      fontSize: "40px",
      color: "#4E342C",
      fontFamily: "Poppins",
      fontWeight: 800,
    },
    body1: {
      fontSize: 22,
      color: "#404040",
    },
    thumbnail: {
      fontSize: 14,
      fontFamily: "Poppins",
      color: "#404040",
      opacity: 0.8,
      fontWeight: "bolder",
    },
    thumbnail2: {
      fontSize: 20,
      fontFamily: "Poppins",
      color: "#404040",
      fontWeight: "bolder",
    },
    lineText: {
      fontSize: 22,
      fontFamily: "Poppins",
      color: "#4E342C",
      fontWeight: "bolder",
    },
    thumbnail3: {
      fontSize: 22,
      fontFamily: "Poppins",
      color: "#404040",
      fontWeight: "normal",
    },
    h1: {
      fontSize: 34,
      fontWeight: "bolder",
      color: "#4e342c",
    },
    h2: {
      fontSize: 28,
      fontWeight: "bolder",
      color: "#4e342c",
    },
    h4: {
      fontSize: 26,
      fontWeight: "bolder",
      color: "#4e342c",
    },
    h5: {
      fontSize: 22,
      fontWeight: "bolder",
      color: "#404040",
    },
  },
});
theme = responsiveFontSizes(theme);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <StyledEngineProvider injectFirst>
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </Provider>
</StyledEngineProvider>
);