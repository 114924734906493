import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router";
import SelectLanguage from "./selectLanguage";
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  upperGovtLogo2: {
    height: 50,
  },
}));

export default function TemporaryDrawer(props) {
  const { open, setOpen, list: data, gate, dataa } = props;
  const navigate = useNavigate();
  const classes = useStyles()

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
    >
      <List>
        <ListItem key={1} disablePadding>
          <ListItemText
            primary={
              <Box
                sx={{
                  display: "flex",
                  marginLeft: "15px",
                  flexDirection: 'column',
                  marginBottom: "30px",
                }}
              >
                <SelectLanguage />
                <Box
                sx={{
                  display: "flex",
                  marginLeft: "15px",
                  marginTop: "30px",
                }}
              >
                <img
                alt=""
                  src={require("./assets/azadikamahotsav.png")}
                  className={classes.upperGovtLogo2}
                />
                <img
                alt=""
                  src={require("./assets/g20.png")}
                  className={classes.upperGovtLogo2}
                />
              </Box>
              </Box>
            }
          />
        </ListItem>
        {/* {data.map((key, index) => (
          <>
            <ListItem key={key} disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate(`/gate${dataa[key][0]?.pagelink}`);
                  setOpen(false);
                }}
              >
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontWeight: "bold",
                        color: key === gate ? "#DB7A11" : "black",
                      }}
                    >
                      {key.replaceAll("_", " ")}
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
            <Divider />
          </>
        ))} */}
      </List>
    </Box>
  );

  const anchor = "right";

  return (
    <div>
      <React.Fragment key={anchor}>
        <Drawer anchor={anchor} open={open} onClose={() => setOpen(false)}>
          {list(anchor)}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
